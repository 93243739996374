import AWS from "aws-sdk";
import { s3Config } from "./s3Config";

const S3_BUCKET = s3Config.bucketName;
const REGION = s3Config.region;

AWS.config.update({
	accessKeyId: s3Config.accessKeyId,
	secretAccessKey: s3Config.secretAccessKey,
});

const myBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET },
	region: REGION,
});

const uploadFileNative = async (file: File, object_name: string) => {
	const params = {
		Body: file,
		Bucket: S3_BUCKET,
		Key: object_name,
	};

	let res = await myBucket.upload(params).promise();

	return res;
};

export default uploadFileNative;
