import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";

const NavBar = () => {
	return (
		<Navbar bg="dark" variant="dark">
			<Container fluid>
				<Navbar.Brand>
					<img src={require("./agni_logo_white.png")} alt="Agni" width="80" height="30" />
				</Navbar.Brand>
				<Nav className="me-auto">
					<Nav.Link href="https://www.agni.law" target="__blank">
						Contact Us
					</Nav.Link>
				</Nav>
			</Container>
		</Navbar>
	);
};

export default NavBar;
