import "./App.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { IsLoggedInProvider } from "./context/IsLoggedIn";

import RiskView from "./views/RiskView";
import NavBar from "./components/staticUI/NavBar";
import DashboardView from "./views/DashboardView";
import LoginView from "./views/LoginView";
import { UserInfoProvider } from "./context/userInfo";

function App() {
	return (
		<IsLoggedInProvider>
			<UserInfoProvider>
				<>
					<NavBar />
					<Router>
						<Routes>
							<Route path="/" element={<LoginView />} />
							<Route path="/review" element={<RiskView />} />
							<Route path="/dashboard" element={<DashboardView />} />
						</Routes>
					</Router>
				</>
			</UserInfoProvider>
		</IsLoggedInProvider>
	);
}

export default App;
