import React, { useContext, useState, PropsWithChildren } from "react";

interface userInfoInterface {
	email: string;
	org: string;
	role: string;
}

const userInfoContext = React.createContext<userInfoInterface>({
	email: "",
	org: "",
	role: "",
});

const userInfoUpdateContext = React.createContext<
	React.Dispatch<React.SetStateAction<userInfoInterface>>
>(() => {});

export function useUserInfo() {
	return useContext(userInfoContext);
}

export function useUserInfoUpdate() {
	return useContext(userInfoUpdateContext);
}

export function UserInfoProvider({ children }: PropsWithChildren<{}>) {
	const [userInfo, setUserInfo] = useState<userInfoInterface>({
		email: "",
		org: "",
		role: "",
	});

	return (
		<>
			<userInfoContext.Provider value={userInfo}>
				<userInfoUpdateContext.Provider value={setUserInfo}>
					{children}
				</userInfoUpdateContext.Provider>
			</userInfoContext.Provider>
		</>
	);
}
