interface s3ConfigInterface {
	bucketName: string;
	dirName?: string;
	region: string;
	accessKeyId: string;
	secretAccessKey: string;
}
console.log(process.env.AWS_ACCESS_KEY_ID);
export const s3Config: s3ConfigInterface = {
	bucketName: "agnicontracts",
	dirName: "agni" /* Optional */,
	region: "us-east-2",
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY ? process.env.REACT_APP_AWS_ACCESS_KEY : "",
	secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
		? process.env.REACT_APP_SECRET_ACCESS_KEY
		: "",

	// s3Url: ''     /* Optional */
};
