import React, { useContext, useState, PropsWithChildren } from "react";

const isLoggedInContext = React.createContext(false);
const isLoggedInUpdateContext = React.createContext<React.Dispatch<React.SetStateAction<boolean>>>(
	() => {}
);

export function useIsLoggedIn() {
	return useContext(isLoggedInContext);
}

export function useIsLoggedInUpdate() {
	return useContext(isLoggedInUpdateContext);
}

export function IsLoggedInProvider({ children }: PropsWithChildren<{}>) {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	return (
		<>
			<isLoggedInContext.Provider value={isLoggedIn}>
				<isLoggedInUpdateContext.Provider value={setIsLoggedIn}>
					{children}
				</isLoggedInUpdateContext.Provider>
			</isLoggedInContext.Provider>
		</>
	);
}
