import React, { CSSProperties, useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { AiOutlineCopy } from "react-icons/ai";
import { getPositionOfLineAndCharacter } from "typescript";
import { cardInfo } from "./sideBarTypes";

interface agniCardProps {
	cardInfo: cardInfo;
	key: string;
	annotationManager: any;
}

const AgniCard = React.forwardRef<HTMLDivElement, agniCardProps>(
	({ cardInfo, annotationManager }, ref) => {
		let high_confidence_cutoff = 0.9;
		let medium_confidence_cutoff = 0.7;
		let confidence_class = "success"; // success, warning, danger
		let confidence_text = "Confident";

		if (cardInfo.confidence && cardInfo.label_type === "clause") {
			if (cardInfo.confidence > high_confidence_cutoff) {
				confidence_class = "success";
				confidence_text = "Confident";
			} else if (cardInfo.confidence > medium_confidence_cutoff) {
				confidence_class = "warning";
				confidence_text = "Moderate";
			} else {
				confidence_class = "danger";
				confidence_text = "Not confident";
			}
		}

		const [isCopied, setIsCopied] = useState(false);

		function handleCardClick(event: any) {
			annotationManager
				.selectAnnotation(cardInfo.annotations.id)
				.then(() => console.log("Selected annotation"));
		}

		let clipboardStyle: CSSProperties = {
			color: isCopied ? "green" : "black",
			position: "absolute",
			top: "0px",
			right: "0px",
		};
		useEffect(() => {
			clipboardStyle = {
				color: isCopied ? "green" : "black",
				position: "absolute",
				top: "0px",
				right: "0px",
			};
		}, [isCopied]);

		return (
			<Card ref={ref} onClick={(e) => handleCardClick(e)} id={cardInfo.id}>
				<Card.Body>
					<div
						style={{ position: "relative" }}
						className="description__icon"
						onClick={async () => {
							await navigator.clipboard.writeText(cardInfo.display_text);
							setIsCopied(true);
						}}
					>
						<AiOutlineCopy style={{ ...clipboardStyle }} />
					</div>

					<Card.Title> {cardInfo.title} </Card.Title>
					<Card.Text> {cardInfo.display_text} </Card.Text>

					<div style={{ position: "relative" }}>
						<Badge
							pill
							bg={confidence_class}
							text="light"
							style={{
								position: "absolute",
								bottom: "0px",
								right: "0px",
								// color: "white",
								fontWeight: "normal",
							}}
						>
							{confidence_text}
						</Badge>

						{cardInfo.is_custom && (
							<Badge
								pill
								bg="info"
								style={{
									position: "relative",
									bottom: "0px",
									right: "0px",
									color: "white",
									fontWeight: "normal",
								}}
							>
								is custom
							</Badge>
						)}
					</div>
				</Card.Body>
			</Card>
		);
	}
);

export default AgniCard;
