import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab, Spinner, Dropdown, DropdownButton } from "react-bootstrap";
import { cardInfo } from "./sideBarTypes";
import AgniCard from "./AgniCard";
import CustomForm from "./CustomForm";

interface SideBarProps {
	annotationManager: any;
	cardData: any;
}

const SideBar = ({ annotationManager, cardData }: SideBarProps) => {
	const [key, setKey] = useState("clauses");
	const [searchString, setSearchString] = useState("");
	const [metadataCards, setMetadataCards] = useState<JSX.Element[]>([]);
	const [clauseCards, setClauseCards] = useState<JSX.Element[]>([]);
	const [selectedRef, setSelectedRef] = useState<any>(null);
	const [showConfident, setShowConfident] = useState(true);
	const [showModerate, setShowModerate] = useState(false);
	const [showNotConfident, setShowNotConfident] = useState(false);

	useEffect(() => {
		if (selectedRef) {
			selectedRef.scrollIntoView({ behavior: "smooth", block: "center" });
			selectedRef.style.border = "2px solid #007bff";
		}
	}, [selectedRef]);

	let metadataInfo: cardInfo[] = [];
	let clauseInfo: cardInfo[] = [];

	let clause_confidence_cutoffs = {
		high: 0.9,
		medium: 0.7,
	};
	let metadata_confidence_cutoffs = {
		high: 0.1,
		medium: 0.05,
	};

	let custom_confidence_cutoffs = {
		high: 0.75,
		medium: 0.65,
	};

	if (cardData) {
		annotationManager.registerEventListener(function(event: any) {
			console.log(event.type, event.data);
			if (event.type === "ANNOTATION_SELECTED") {
				console.log("annotation selected event captured.");
				console.log(event.data.id);
				metadataRefs.current.forEach((ref) => {
					if (ref) {
						if (ref.id === event.data.id) {
							setSelectedRef(() => ref);
							setKey(() => "metadata");
							// ref.scrollIntoView({ behavior: "smooth" });
							// ref.style.border = "2px solid #007bff";
						} else {
							ref.style.border = "none";
						}
					}
				});

				clauseRefs.current.forEach((ref) => {
					if (ref) {
						if (ref.id === event.data.id) {
							setSelectedRef(() => ref);
							setKey(() => "clauses");
							// ref.scrollIntoView({ behavior: "smooth" });
							// ref.style.border = "2px solid #007bff";
						} else {
							ref.style.border = "none";
						}
					}
				});
			} else if (event.type === "ANNOTATION_UNSELECTED") {
				console.log("annotation unselected event captured.");
				metadataRefs.current.forEach((ref) => {
					if (ref) {
						ref.style.border = "none";
						setSelectedRef(() => null);
					}
				});

				clauseRefs.current.forEach((ref) => {
					if (ref) {
						ref.style.border = "none";
						setSelectedRef(() => null);
					}
				});
			}
		});
		let index = 0;

		cardData["metadata"].forEach((metadata_obj: any) => {
			metadataInfo.push({
				id: metadata_obj.annotation.id ? metadata_obj.annotation.id : index,
				label_type: "metadata",
				title: metadata_obj.label_category,
				display_text: metadata_obj.answer,
				annotations: metadata_obj.annotation,
				confidence: metadata_obj.confidence,
				is_custom: metadata_obj.is_custom,
			});
			index++;
		});

		index = 0;

		for (const clause_obj of cardData["clauses"]) {
			let confidence_level = "confident";
			if (clause_obj["is_custom"]) {
				confidence_level =
					clause_obj["confidence_score"] > custom_confidence_cutoffs.high
						? "confidence"
						: clause_obj["confidence_score"] > custom_confidence_cutoffs.medium
						? "moderate"
						: "not_confident";
			} else {
				confidence_level =
					clause_obj["confidence_score"] > clause_confidence_cutoffs.high
						? "confident"
						: clause_obj["confidence_score"] > clause_confidence_cutoffs.medium
						? "moderate"
						: "not_confident";
			}

			if (confidence_level === "moderate") {
				clause_obj["annotation"].target.selector.strokeColor = "#fef3bd";
			} else if (confidence_level === "not_confident") {
				clause_obj["annotation"].target.selector.strokeColor = "#dddddd";
			}

			clauseInfo.push({
				id: clause_obj["annotation"].id,
				label_type: "clause",
				title: clause_obj["label_category"],
				display_text: clause_obj["sentence"],
				annotations: clause_obj["annotation"],
				confidence: clause_obj["confidence_score"],
				confidence_level: confidence_level,
				is_custom: clause_obj["is_custom"],
			});
		}
	}

	let metadataAnnotations = metadataInfo
		.filter((cardInfo: cardInfo) => {
			if (cardInfo.display_text.toLowerCase() !== "not found") return cardInfo;
		})
		.map((cardInfo: cardInfo) => cardInfo.annotations);

	let clauseAnnotations = clauseInfo
		.filter((cardInfo: cardInfo) => {
			if (cardInfo.display_text !== "") return cardInfo;
		})
		.map((cardInfo: cardInfo) => cardInfo.annotations);

	annotationManager
		.addAnnotations(clauseAnnotations)
		.then(() => console.log("Successfully added clause annotations"))
		.catch((error: Error) => console.log(error));

	annotationManager
		.addAnnotations(metadataAnnotations)
		.then(() => console.log("Successfully added metadata annotations"))
		.catch((error: Error) => console.log(error));

	const metadataRefs = useRef<any[]>([]);
	const clauseRefs = useRef<any[]>([]);

	useEffect(() => {
		metadataRefs.current = [];
		setMetadataCards(
			metadataInfo
				.filter((cardInfo: cardInfo) => {
					if (cardInfo.title.toLowerCase().includes(searchString.toLowerCase())) {
						return cardInfo;
					}
					if (cardInfo.display_text.toLowerCase().includes(searchString.toLowerCase())) {
						return cardInfo;
					}
				})
				.map((cardInfo: cardInfo) => (
					<AgniCard
						cardInfo={cardInfo}
						ref={(el) => (metadataRefs.current = [...metadataRefs.current, el])}
						key={cardInfo.id}
						annotationManager={annotationManager}
					/>
				))
		);

		clauseRefs.current = [];

		setClauseCards(
			clauseInfo
				.filter((cardInfo: cardInfo) => {
					if (showConfident && cardInfo.confidence_level === "confident") {
						return cardInfo;
					}
					if (showModerate && cardInfo.confidence_level === "moderate") {
						return cardInfo;
					}
					if (showNotConfident && cardInfo.confidence_level === "not_confident") {
						return cardInfo;
					}

					if (cardInfo.is_custom) {
						return cardInfo;
					}
				})
				.filter((cardInfo: cardInfo) => {
					if (cardInfo.title.toLowerCase().includes(searchString.toLowerCase())) {
						return cardInfo;
					}
					if (cardInfo.display_text.toLowerCase().includes(searchString.toLowerCase())) {
						return cardInfo;
					}
				})
				.map((cardInfo: cardInfo) => (
					<AgniCard
						cardInfo={cardInfo}
						ref={(el) => (clauseRefs.current = [...clauseRefs.current, el])}
						key={cardInfo.id}
						annotationManager={annotationManager}
					/>
				))
		);
		console.log("metadataRefs", metadataRefs);
		console.log("clauseRefs", clauseRefs);
	}, [searchString, showConfident, showModerate, showNotConfident]);

	function handleCheckbox(e: any, checkbox: any, setCheckbox: any) {
		setCheckbox(!checkbox);
	}

	function displaySearchAndFilter() {
		return (
			<div style={{ display: "flex", marginBottom: "5px" }}>
				<input
					type="search"
					placeholder={"Search clauses & metadata"}
					style={{ width: "80%" }}
					onChange={(e) => setSearchString(() => e.target.value)}
					value={searchString}
				/>
				<DropdownButton
					autoClose={false}
					drop="end"
					style={{ width: "20%", marginLeft: "5px" }}
					title="Filters"
					variant="dark"
				>
					<input
						type="checkbox"
						value={"confident"}
						style={{ margin: "5px 5px 5px 5px" }}
						checked={showConfident}
						onChange={(e) => handleCheckbox(e, showConfident, setShowConfident)}
					/>{" "}
					<span>Confident</span>
					<br />
					<input
						type="checkbox"
						value={"moderate"}
						style={{ margin: "5px 5px 5px 5px" }}
						checked={showModerate}
						onChange={(e) => handleCheckbox(e, showModerate, setShowModerate)}
					/>{" "}
					<span>Moderate</span>
					<br />
					<input
						type="checkbox"
						value={"not_confident"}
						style={{ margin: "5px 5px 5px 5px" }}
						checked={showNotConfident}
						onChange={(e) => handleCheckbox(e, showNotConfident, setShowNotConfident)}
					/>{" "}
					<span>Not Confident</span>
				</DropdownButton>
			</div>
		);
	}

	return (
		<Tabs
			id="label_type_tabs"
			activeKey={key}
			onSelect={(k) => k && setKey(k)}
			className="mb-3"
			variant="tabs"
		>
			<Tab eventKey="metadata" title="Metadata">
				{displaySearchAndFilter()}

				{metadataCards}
			</Tab>
			<Tab eventKey="clauses" title="Clauses">
				{displaySearchAndFilter()}

				{clauseCards}
			</Tab>
			<Tab eventKey="custom" title="+">
				<CustomForm selectedText="test_selection" />
			</Tab>
		</Tabs>
	);
};

export default SideBar;
