/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/
declare global {
	interface Window {
		AdobeDC: any;
	}
}

interface previewFileConfig {
	clientId: string;
	divId?: string;
}

const CLIENT_ID = process.env.REACT_APP_ADOBE_CLIENT_ID
	? process.env.REACT_APP_ADOBE_CLIENT_ID
	: "";

class ViewSDKClient {
	readyPromise: Promise<void>;
	adobeDCView: any;

	constructor() {
		this.readyPromise = new Promise((resolve) => {
			if (window.AdobeDC) {
				resolve();
			} else {
				/* Wait for Adobe Document Services PDF Embed API to be ready */
				document.addEventListener("adobe_dc_view_sdk.ready", () => {
					resolve();
				});
			}
		});
		this.adobeDCView = undefined;
	}

	ready() {
		return this.readyPromise;
	}

	previewFile(divId: string | null, viewerConfig: any) {
		const config: previewFileConfig = {
			clientId: CLIENT_ID,
		};

		if (divId) {
			config.divId = divId;
		}

		this.adobeDCView = new window.AdobeDC.View(config);

		const previewFilePromise = this.adobeDCView.previewFile(
			{
				content: {
					location: {
						url: "https://documentcloud.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf",
					},
				},
				metaData: {
					fileName: "Bodea Brochure.pdf",
					id: "6d07d124-ac85-43b3-a867-36930f502ac6",
				},
			},
			viewerConfig
		);

		return previewFilePromise;
	}

	previewFileUsingFilePromise(divId: string, filePromise: Promise<ArrayBuffer>, fileName: string) {
		this.adobeDCView = new window.AdobeDC.View({
			clientId: CLIENT_ID,
			divId,
		});

		const adobeViewer = this.adobeDCView.previewFile(
			{
				content: {
					promise: filePromise,
				},
				metaData: {
					fileName: fileName,
					id: "6d07d124-ac85-43b3-a867-36930f502ac6",
				},
			},
			{
				embedMode: "FULL_WINDOW",
				showAnnotationTools: true,
				includePDFAnnotations: true,
				showLeftHandPanel: true,
				defaultViewMode: "FIT_WIDTH",
				annotationUIConfig: {
					showToolbar: false,
					showCommentsPanel: false,
					showToolsOnTextSelection: true,
					downloadWithAnnotations: true,
					printWithAnnotations: true,
				},
				enableAnnotationAPIs: true,
			}
		);
		return adobeViewer;
	}

	registerSaveApiHandler() {
		const saveApiHandler = (metaData: any, content: any, options: any) => {
			console.log(metaData, content, options);
			return new Promise((resolve) => {
				setTimeout(() => {
					const response = {
						code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
						data: {
							metaData: Object.assign(metaData, { updatedAt: new Date().getTime() }),
						},
					};
					resolve(response);
				}, 2000);
			});
		};

		this.adobeDCView.registerCallback(
			window.AdobeDC.View.Enum.CallbackType.SAVE_API,
			saveApiHandler,
			{}
		);
	}

	registerEventsHandler() {
		this.adobeDCView.registerCallback(
			window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
			(event: string) => {
				console.log(event);
			},
			{
				enablePDFAnalytics: true,
			}
		);
	}
}

export default ViewSDKClient;
