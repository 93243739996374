import React, { useEffect, useState } from "react";
import "./loginStyle.css";

import api from "../../Api/api";
import { useIsLoggedIn, useIsLoggedInUpdate } from "../../context/IsLoggedIn";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useUserInfo, useUserInfoUpdate } from "../../context/userInfo";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const isLoggedIn = useIsLoggedIn();
	const updateLoggedIn = useIsLoggedInUpdate();
	const navigate = useNavigate();
	const [loginFailed, setLoginFailed] = useState(false);
	const userInfo = useUserInfo();
	const updateUserInfo = useUserInfoUpdate();

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const request_body = JSON.stringify({ email: email, password: password });
		await api.post("/user/login/", request_body).then((response) => {
			// console.log(response);
			const user = response.data.user;

			if (user.status !== "error") {
				updateLoggedIn(true);
				updateUserInfo((prevState) => {
					return {
						email: user.email,
						org: user.org,
						role: user.role,
					};
				});

				navigate("/review");
			} else {
				setLoginFailed(true);
			}
		});
	}

	return (
		<div className="Auth-form-container">
			<form className="Auth-form" onSubmit={(e) => handleSubmit(e)}>
				<div className="Auth-form-content">
					<h3 className="Auth-form-title">Sign In</h3>
					<div className="form-group mt-3">
						<label>Username / Email address</label>
						<input
							type="text"
							className="form-control mt-1"
							placeholder="Enter email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="form-group mt-3">
						<label>Password</label>
						<input
							type="password"
							className="form-control mt-1"
							placeholder="Enter password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="d-grid gap-2 mt-3">
						<button type="submit" className="btn btn-dark">
							Submit
						</button>
					</div>
					<br />
				</div>
				{loginFailed && (
					<div className="container">
						<Alert variant="danger">Username or password did not match!</Alert>
					</div>
				)}
			</form>
		</div>
	);
};

export default Login;
