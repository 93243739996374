import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PdfDisplay from "../components/pdfDisplay/PdfDisplay";
import { useIsLoggedIn } from "../context/IsLoggedIn";

const RiskView = () => {
	const isLoggedIn = useIsLoggedIn();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/");
		}
	}, []);

	return (
		<div>
			<PdfDisplay />
		</div>
	);
};

export default RiskView;
