import React from "react";
import Login from "../components/login/Login";

const LoginView = () => {
	return (
		<div>
			<Login />
		</div>
	);
};

export default LoginView;
