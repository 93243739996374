import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ViewSDKClient from "./ViewSDKClient";

import SideBar from "../sidebar/SideBar";
import "./style.css";
import uploadFileNative from "../../s3_utils/uploadFileNative";
import SpinnerAgni from "../staticUI/SpinnerAgni";
// import { UploadToS3 } from 'react-upload-to-s3'

import api from "../../Api/api";
import { useUserInfo } from "../../context/userInfo";

const PdfDisplay: React.FC = () => {
	const [annotationManager, setAnnotationManager] = useState<any>(undefined);
	const [isUploaded, setIsUploaded] = useState<boolean>(false);
	const [cardData, setCardData] = useState<Object | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const user = useUserInfo();

	const isValidPDF = (file: File) => {
		if (file.type === "application/pdf") {
			return true;
		}
		if (file.type === "" && file.name) {
			const fileName = file.name;
			const lastDotIndex = fileName.lastIndexOf(".");
			if (lastDotIndex === -1 || fileName.substr(lastDotIndex).toUpperCase() !== "PDF")
				return false;
			return true;
		}
		return false;
	};

	async function getCardData(file: File): Promise<any> {
		// const request_body = JSON.stringify({ base64_pdf: file });

		let data: Object = await api
			.get("/document/", {
				params: {
					document_name: file.name,
					user_email: user.email,
					user_org: user.org,
					// document_name: "mock",
				},
			})
			.then((response) => {
				data = response.data;
				setCardData(data);
				console.log("Setting isLoading to false");
				setIsLoading(false);
				return data;
			});
		return data;
	}

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const viewSDKClient: any = new ViewSDKClient();

		viewSDKClient.ready().then(async () => {
			const files = event.target.files;

			if (files && files.length > 0 && isValidPDF(files[0])) {
				const reader = new FileReader();

				reader.onloadend = async function(fileLoadedEvent) {
					let file = fileLoadedEvent && fileLoadedEvent.target && fileLoadedEvent.target.result;
					if (file) {
						const filePromise = Promise.resolve(file);
						let adobeViewer = await viewSDKClient.previewFileUsingFilePromise(
							"pdf-div",
							filePromise,
							files[0].name
						);
						adobeViewer
							.getAnnotationManager()
							.then((annotManager: any) => {
								console.log("Updating isUploaded to true");
								setIsUploaded(() => true);
								setAnnotationManager(annotManager);
							})
							.catch((error: any) => {
								console.log(error);
							});
					}
				};

				reader.readAsArrayBuffer(files[0]);
				let object_name = user.org + "/" + user.email + "/" + files[0].name;
				let res = await uploadFileNative(files[0], object_name).then(async () => {
					let response = await getCardData(files[0]);
					console.log("OOTB working");
					console.log(response);
				});
			}
		});
	};

	return (
		<Container fluid>
			<Row>
				<Col xs={3}>
					{isUploaded &&
						(isLoading ? (
							<div
								style={{
									height: "100vh",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<SpinnerAgni />
							</div>
						) : (
							<div style={{ height: "100vh", overflow: "scroll" }}>
								{cardData && <SideBar annotationManager={annotationManager} cardData={cardData} />}
							</div>
						))}
				</Col>
				<Col>
					<div id="pdf-div" className="pdf-view full-window-div" style={{ height: "100vh" }}>
						<div style={{ margin: "50px" }}>
							<label htmlFor="file-picker" className="file-picker">
								Upload Contract
							</label>

							<input
								onChange={handleFileUpload}
								type="file"
								id="file-picker"
								name="file-picker"
								accept="application/pdf"
								className="file-input"
							/>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default PdfDisplay;
