import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
console.log("BASE_URL", BASE_URL);

const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
		"Access-Control-Allow-Headers": "Content-Type, Authorization, Content-Length, X-Requested-With",
		"x-token": "agni",
	},
});

export default api;
