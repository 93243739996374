import React from "react";

const DisplayContracts = () => {
	return (
		<div>
			<span>Show tables using this component</span>
		</div>
	);
};

export default DisplayContracts;
