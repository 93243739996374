import React from "react";
import DisplayContracts from "../components/displayContracts/DisplayContracts";

const DashboardView = () => {
	return (
		<div>
			Dashboard View
			<DisplayContracts />
		</div>
	);
};

export default DashboardView;
