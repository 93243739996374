import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";

import api from "../../Api/api";
import { useUserInfo } from "../../context/userInfo";

interface customAddInputs {
	label_type: string;
	label_category: string;
	highlighted_text: string;
	highlighted_text_sentence?: string;
}

enum statusEnum {
	"success" = "success",
	"failure" = "danger",
	"pending" = "pending",
}

interface customFormProps {
	selectedText: string;
	selectedTextSentence?: string;
}

const CustomForm = ({ selectedText, selectedTextSentence }: customFormProps) => {
	const [customAddition, setCustomAddition] = useState<customAddInputs>({
		label_type: "clause",
		label_category: "",
		highlighted_text: "",
		highlighted_text_sentence: "",
	});
	const [submissionStatus, setSubmissionStatus] = useState<statusEnum>(statusEnum.pending);
	const user = useUserInfo();

	useEffect(() => {
		if (
			customAddition.label_type == "clause" &&
			customAddition.label_category == "" &&
			customAddition.highlighted_text == "" &&
			customAddition.highlighted_text_sentence == ""
		) {
			console.log("Skipping useEffect since all fields are emptied");
		} else {
			setSubmissionStatus(statusEnum.pending);
		}
	}, [customAddition]);

	async function handleSubmit(e: any) {
		e.preventDefault();
		// const formData = new FormData(e.target),
		// 	formDataObj = Object.fromEntries(formData.entries());
		// console.log(formDataObj);
		console.log(customAddition);

		let data: Object = await api
			.post("/customizable/add", { ...customAddition, email: user.email, org: user.org })
			.then((response) => {
				// alert("Custom addition completed successfully!");

				setCustomAddition(() => ({
					label_type: "clause",
					label_category: "",
					highlighted_text: "",
					highlighted_text_sentence: "",
				}));
				setSubmissionStatus(() => statusEnum.success);
				data = response.data;
				console.log("Custom form data addition response: ", data);

				return data;
			})
			.catch((error) => {
				// alert("Error in custom addition!");
				setSubmissionStatus(statusEnum.failure);
				console.log("Custom form data addition error: ", error);
				return error;
			});
		return data;
	}

	return (
		<div style={{ width: "90%" }}>
			<div>
				<Form onSubmit={(e) => handleSubmit(e)}>
					<Form.Group className="mb-3" controlId="formRadio">
						<Form.Label>Label Type</Form.Label>
						<div key={`inline-radio`} className="mb-3">
							<Form.Check
								inline
								label="Clause"
								value="clause"
								name="label_type"
								type="radio"
								checked={customAddition.label_type === "clause"}
								id={`radio-clause`}
								onChange={(e) =>
									setCustomAddition({ ...customAddition, label_type: e.target.value })
								}
							/>
							<Form.Check
								inline
								label="Metadata"
								value="metadata"
								name="label_type"
								type="radio"
								checked={customAddition.label_type === "metadata"}
								id={`radio-metadata`}
								onChange={(e) =>
									setCustomAddition({ ...customAddition, label_type: e.target.value })
								}
							/>
						</div>
					</Form.Group>

					<Form.Group className="mb-3" controlId="label_category">
						<Form.Label>Name of {customAddition.label_type}</Form.Label>
						<Form.Control
							name="label_category"
							type="text"
							placeholder="Force Majeure"
							value={customAddition.label_category}
							onChange={(e) =>
								setCustomAddition({ ...customAddition, label_category: e.target.value })
							}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="highlighted_text">
						<Form.Label>Highlighted Text</Form.Label>
						<Form.Control
							name="highlighted_text"
							type="textarea"
							placeholder="Text you want highlighted"
							value={customAddition.highlighted_text}
							onChange={(e) =>
								setCustomAddition({ ...customAddition, highlighted_text: e.target.value })
							}
						/>
					</Form.Group>
					{customAddition.label_type === "metadata" && (
						<Form.Group className="mb-3" controlId="highlighted_text_sentence">
							<Form.Label>Sentence containing highlighted text</Form.Label>
							<Form.Control
								name="highlighted_text_sentence"
								as="textarea"
								rows={3}
								placeholder="Sentence your highlighted text is present in"
								value={customAddition.highlighted_text_sentence}
								onChange={(e) => {
									setCustomAddition({
										...customAddition,
										highlighted_text_sentence: e.target.value,
									});
								}}
							/>
						</Form.Group>
					)}

					<Button
						variant="dark"
						style={{ display: "flex", justifyContent: "center" }}
						type="submit"
					>
						Submit
					</Button>
				</Form>
			</div>
			<br />
			{submissionStatus !== statusEnum.pending && (
				<div className="container">
					<Alert variant={submissionStatus}>
						Custom addition {submissionStatus === statusEnum.success ? "completed" : "failed"}!
					</Alert>
				</div>
			)}
		</div>
	);
};

export default CustomForm;
